<template>
  <div>
    <b-card>
      <template #header>
        Excel Parser Brands
        <span class="float-right">
          <b-button size="sm" variant="secondary" @click="() => $router.push('/excel-parsers')">Back</b-button>
          <b-button class="ml-1" size="sm" variant="primary" @click="onFormViewModal('add', null)">Add Brand</b-button>
        </span>
      </template>
      <b-card-text>
        <div class="filter d-flex justify-content-between">
        <div class="left-controls">
          
        </div>
        <div class="right-controls">
          <b-form-group label="Search" label-cols-lg="auto">
            <b-form-input v-model.trim="search" placeholder="Brand or Type" type="search"></b-form-input>
          </b-form-group>
        </div>
        </div>
        <div class="table">
          <b-table
          id="upc-generator-table"
          class="border-bottom"
          small
          show-empty
          :filter="search"
          :filter-included-fields="['brandName', 'parserType']"
          @filtered="onFiltered"
          :empty-text="tableLoading ? 'Getting Data...' : 'There are no records to show'"
          :busy="tableLoading"
          :items="items"
          :fields="fields"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          stacked="sm"
          hover>
            <template #cell(status)="data">
              <b-badge class="mr-1" style="font-weight: 400;" v-if="!data.item.parserConfig" variant="warning">No Parser Config</b-badge>
              <b-badge class="mr-1" style="font-weight: 400;" v-if="!data.item.templateLink" variant="warning">No Able2Extract Template</b-badge>
              <b-badge class="mr-1" style="font-weight: 400;" v-if="!data.item.sampleFile" variant="warning">No Sample File</b-badge>
              <b-badge class="mr-1" style="font-weight: 400;" v-if="data.item.sampleFile && data.item.templateLink && data.item.parserConfig" variant="success">Complete</b-badge>
            </template>

            <template #cell(actions)="data">
              <b-dropdown split text="View Config" size="sm" variant="primary" @click="onFormViewModal('update', data.item)">
                <b-dropdown-item @click="onDownloadFile(data.item.sampleFile)">Download Sample File</b-dropdown-item>
                <b-dropdown-item @click="onDownloadFile(data.item.templateLink)">Download Able2Extract Template</b-dropdown-item>
                <b-dropdown-item @click="onDelete(data.item)">Delete</b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <b-pagination
          :disabled="tableLoading"
          v-if="items.length > 0"
          v-model="pagination.currentPage"
          :total-rows="totalRows"
          :per-page="pagination.perPage"
          aria-controls="upc-generator-table"
          align="center"
          first-number
          last-number
          hide-goto-end-buttons
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <b-modal v-model="formModal" :title="formType === 'add' ? 'Add' : 'Update'" size="lg" hide-footer no-close-on-backdrop @close="onFormCancelModal">
      <b-card-text>
        <b-form-group class="mt-n1" label-size="sm" label="Enter Brand:" label-for="form-brand" >
          <b-form-input size="sm" id="form-brand" v-model="formData.brandName"></b-form-input>
        </b-form-group>
        <b-form-group class="mt-n1" label-size="sm" label="Enter Type:" label-for="form-parserType" >
          <b-form-input size="sm" id="form-parserType" v-model="formData.parserType"></b-form-input>
        </b-form-group>
        <b-form-group class="mt-n1" label-size="sm" label="Upload Able2Extract Template:" label-for="form-template-link" >
          <b-form-file size="sm" id="form-template-link" v-model="formData.templateLink"></b-form-file>
          <b-link v-if="formData.templateLinkData" :href="formData.templateLinkData">
            <small>Current Able2Extract Template</small>
          </b-link>
        </b-form-group>
        <b-form-group class="mt-n1" label-size="sm" label="Upload Sample PDF File:" label-for="form-sampleFile">
          <b-form-file size="sm" id="form-sampleFile" v-model="formData.sampleFile"></b-form-file>
          <b-link v-if="formData.sampleFileData" :href="formData.sampleFileData">
            <small>Current Sample PDF File</small>
          </b-link>
        </b-form-group>
        <b-form-group class="mt-n1 code-container" label-size="sm" label="Parser Config:">
          <editor class="code-editor" v-model="formData.parserConfig" lang="javascript" height="500" @init="initEditor"></editor>
        </b-form-group>
        <b-form-group class="border-top mb-n1">
          <div class="controls mt-2">
            <b-button class="mr-1" size="sm" variant="secondary" @click="copyToClipboard">Copy Parser Config</b-button>
            <b-button size="sm" variant="secondary" @click="showDefaultConfigCode">Show Default Code</b-button>
            <span class=" float-right ">
              <b-button :disabled="formLoading" class="mr-1" size="sm" variant="secondary" @click="onFormCancelModal">Cancel</b-button>
              <b-button :disabled="formLoading" size="sm" variant="primary" @click="onFormSubmit">
                <b-spinner v-if="formLoading" label="Spinning" small></b-spinner> Submit
              </b-button>
            </span>
            
          </div>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import api from '../services/apiService'
import editor from 'vue2-ace-editor'
export default {
  components: {
    editor
  },
  data(){
    return {
      //import modal
      formModal: false,
      formLoading: false,
      formType: 'add',
      formData: {
        id: null,
        brandName: '',
        parserType: '',
        parserConfig: '',
        templateLink: null,
        templateLinkData: null,
        sampleFile: null,
        sampleFileData: null,
      },
      //table
      search: '',
      items: [],
      fields: [
        {
          key: "createdAt",
          label: "Date Created",
          class: 'align-middle'
        },
        {
          key: "brandName",
          label: "Brand",
          class: 'align-middle'
        },
        {
          key: "parserType",
          label: "Type",
          class: 'align-middle'
        },
        {
          key: "status",
          label: "Status",
          class: 'align-middle'
        },
        {
          key: "actions",
          label: "Actions",
          class: 'align-middle'
        }
      ],
      tableLoading: false,
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: 0
      },

      filteredItems: [],
      selectedItems: [],
      updateSelectedItemsLoading: false
    }
  },
  computed: {
    totalRows(){
      return this.pagination.totalRows > 0 ? this.pagination.totalRows : this.items.length
    }
  },
  created(){
    this.loadItems();
  },
  methods: {
    initEditor:function (editor) {
      require('brace/mode/javascript');
      require('brace/theme/chrome');
    },
    onFiltered(filteredItems){
      if(filteredItems.length > 0){
        this.filteredItems = filteredItems
      } else {
        this.filteredItems = []
      }

      this.pagination.totalRows = filteredItems.length
      this.pagination.currentPage = 1
    },
    async onFormViewModal(type, data){
      this.formModal = true
      if(type === 'add'){
        this.formType = 'add'
        this.formData = { id: null, brandName: '', parserType: "", parserConfig: '' }
      }else if(type === 'update'){
        this.formType = 'update'
        this.formData = {
          id: data.id,
          brandName: data.brandName,
          parserType: data.parserType,
          parserConfig: data.parserConfig,
          templateLinkData: data.templateLink,
          sampleFileData: data.sampleFile
        }
      }
    },
    onFormCancelModal(){
      this.formModal = false
      this.formData = { id: null, brandName: "", parserType: "", parserConfig: "" }
    },
    async onFormSubmit(){
      console.log(this.formData)
      this.formLoading = true
      try {
        let response;
        let formData = new FormData()
        formData.append('brandName', this.formData.brandName)
        formData.append('parserType', this.formData.parserType)
        formData.append('parserConfig', this.formData.parserConfig)
        if(this.formData.templateLink){
          formData.append('files', this.formData.templateLink)
        }
        if(this.formData.sampleFile){
          formData.append('files', this.formData.sampleFile)
        }

        if(this.formType === 'add'){
          response = await api.post(`/api/excel-parser/brands`, formData);
        } else if(this.formType === 'update'){
          response = await api.put(`/api/excel-parser/brands/${this.formData.id}`, formData);
        }
        
        this.$bvToast.toast(response.data.message, { title: `Success`, variant: 'success', solid: true })
        this.formLoading = false
        this.onFormCancelModal();
        await this.loadItems();
      } catch (error) {
        this.$bvToast.toast(error.data.message, { title: `Error`, variant: 'danger', solid: true })
        this.formLoading = false
      }
    },
    async loadItems(){
      this.items = []
      this.tableLoading = true
      let response = await api.get('/api/excel-parser/brands')
      this.items = response.data.map(e => {
        return { ...e, selected: false }
      })
      this.pagination = {
        ...this.pagination,
        totalRows: response.data.length
      }
      this.tableLoading = false
    },
    async onDelete(data){
      let isConfirmed = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this config?', { title: 'Confirm', buttonSize: 'sm', okTitle: 'Confirm', cancelTitle: 'Cancel', footerClass: 'p-2', hideHeaderClose: false })
      if(isConfirmed){
        let response = await api.delete(`/api/excel-parser/brands/${data.id}`)
        this.$bvToast.toast(response.data.message, { title: 'Success', variant: 'success', solid: true })
        await this.loadItems()
      }
    },
    async copyToClipboard(){
      if(this.formData.parserConfig){
        await navigator.clipboard.writeText(this.formData.parserConfig);
        this.$bvToast.toast("Copied to clipboard", { title: 'Success', variant: 'success', solid: true })
      } else {
        this.$bvToast.toast("Parser config is empty!", { title: 'Error', variant: 'danger', solid: true })
      }
    },
    onDownloadFile(fileUrl){
      if(fileUrl){
        window.open(fileUrl, '_blank');
      } else {
        this.$bvToast.toast("No URL File to download!", {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
    },
    showDefaultConfigCode(){
      let defaultCode = `async function parse(workbook){
    //========== Dont update this part if you dont have knowledge in ExcelJs ===============
    let result = [];
    const worksheet = await workbook.getWorksheet(1); //get the first sheet in your workbook
    const rows = worksheet.getSheetValues(); //get all row values
    //=======================================================================================
    
    //If you want to have a header, follow the format below; Delete if not needed;
    let defaultHeader = { itemNumber: 'Item Number', size: 'Size', quantity: 'Quantity', itemCost: 'Item Cost', totalCost: 'Total Cost' }
    result.push(defaultHeader);
    let obj = {};
    for (let x in rows) {
        let row = rows[x];
        
    }
    return result; //Dont delete this line
}`
      if(!this.formData.parserConfig){
        this.formData.parserConfig = defaultCode
      }else{
        let isConfirmed = this.$bvModal.msgBoxConfirm('Are you sure you want to replace the code with the default code?', { title: 'Confirm', buttonSize: 'sm', okTitle: 'Confirm', cancelTitle: 'Cancel', footerClass: 'p-2', hideHeaderClose: false })
        if(isConfirmed){
          this.formData.parserConfig = defaultCode
        }
      }
    }
  }
}
</script>

<style>
.code-editor{
  border: 1px solid #DCDCDC;
  /* border-radius: 5px; */
}
</style>